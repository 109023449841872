import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Title = styled.h1`
  font-size: 1.375rem;
  font-weight: 500;
`;

const Highlight = styled.p`
  font-weight: 500;
`;

function AboutPage() {
  return (
    <Layout>
      <SEO title="About Us" />
      <Title>Giới thiệu</Title>

      <p>
        Ngân hàng video giáo dục Trạng là một dự án khởi nghiệp của những người bạn trẻ giáo viên,
        kỹ sư, thiết kế đồ hoạ, kinh tế,... mong muốn Mang thế giới vào trong lớp học để hỗ trợ thầy
        cô và học sinh được học tập vui thích hàng ngày.
      </p>

      <p>
        Ngân hàng giúp các thầy cô và các em học sinh trải nghiệm học tập đa dạng kiến thức trong
        cấp học của mình.
      </p>

      <p>Nội dung video bao gồm:</p>

      <ol>
        <li>Hình ảnh quay thật</li>
        <li>Mô phỏng bản chất bên trong</li>
        <li>Tích hợp kiến thức với ứng dụng</li>
      </ol>

      <p>Giúp:</p>

      <Highlight>THẦY CÔ</Highlight>

      <ul>
        <li>Truyền đạt nhanh kiến thức</li>
        <li>Tiết kiệm thời gian làm mới giáo án.</li>
      </ul>

      <Highlight>HỌC SINH</Highlight>

      <ul>
        <li>Hiểu bài ngay tại lớp</li>
        <li>Thích học</li>
        <li>Tự ôn tập kiến thức tại nhà</li>
        <li>Kích thích sáng tạo</li>
        <li>Biết ứng dụng kiến thức vào cuộc sống.</li>
      </ul>

      <p>
        Chúng tôi hy vọng sản phẩm của mình sẽ hữu ích và rất cầu thị sự đóng góp từ các bạn để nội
        dung video ngày càng gần gũi và thiết thực!
      </p>

      <p>
        <span>Xin gửi những góp ý đến chúng tôi </span>
        <Link to="/contact">tại đây.</Link>
      </p>

      <Highlight>Trân trọng!</Highlight>
    </Layout>
  );
}

export default AboutPage;
